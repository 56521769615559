import { useMediaQuery, useTheme } from "@mui/material";

export const useBreakpoint = () => {
  const theme = useTheme();

  return {
    isXs: useMediaQuery(theme.breakpoints.only("xs")),
    isSm: useMediaQuery(theme.breakpoints.only("sm")),
    isMd: useMediaQuery(theme.breakpoints.only("md")),
    isLg: useMediaQuery(theme.breakpoints.only("lg")),
    isXl: useMediaQuery(theme.breakpoints.only("xl")),
    isSmallOrUp: useMediaQuery(theme.breakpoints.up("sm")),
    isMediumOrUp: useMediaQuery(theme.breakpoints.up("md")),
    isLargeOrUp: useMediaQuery(theme.breakpoints.up("lg")),
    smallParentCard: useMediaQuery("(max-width: 1256px)"),
  };
};
