import { createAppSlice } from "@/store/createAppSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { MatchRequestDTO } from "./matchApiSlice";

interface Slice {
  orderMatchRequests: MatchRequestDTO[];
}

const initialState: Slice = {
  orderMatchRequests: [],
};

export const orderMatchRequestSlice = createAppSlice({
  name: "orderMatchRequest",
  initialState,
  reducers: (create) => ({
    setOrderMatchRequests: create.reducer((state, action: PayloadAction<MatchRequestDTO[]>) => {
      state.orderMatchRequests = action.payload;
    }),
  }),
  selectors: {
    selectOrderMatchRequests: (state) => state.orderMatchRequests,
  },
});

export const { setOrderMatchRequests } = orderMatchRequestSlice.actions;
export const { selectOrderMatchRequests } = orderMatchRequestSlice.selectors;
