import ErrorCard from "@/components/ErrorCard";
import { useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import Skeleton from "@mui/material/Skeleton";
import { selectParent } from "../parent/parentSlice";
import OrderCard from "./OrderCard";
import { useGetMyOrdersQuery } from "./ordersApiSlice";

const MyOrders = () => {
  const selectedParent = useAppSelector(selectParent);

  // Only show this component if there is a selected parent order.
  const {
    currentData: myOrders,
    error,
    isError,
    isLoading,
    refetch,
  } = useGetMyOrdersQuery(selectedParent?.parent_order_id || "", {
    skip: !selectedParent,
    pollingInterval: refetchTimeouts.fast,
  });

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={120} width="100%" variant="rounded" />;
  }

  if (myOrders) {
    return myOrders.map((order, i) => <OrderCard key={order.order_id} index={i} order={order} />);
  }

  return null;
};

export default MyOrders;
