import { themeColors } from "@/app/theme";
import ContactFooter from "@/components/ContactFooter";
import AppBar from "@/features/dashboard/components/AppBar";
import { useAppDispatch } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { Fade, IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import z from "zod";
import { handlePasswordChange } from "../auth/authSlice";

type FormData = z.infer<typeof schema>;
const schema = z
  .object({
    password: z
      .string()
      .min(10, { message: "Password must be at least 10 characters long" })
      .max(30, { message: "Password can not be longer than 30 characters" })
      .refine((password) => /[A-Z]/.test(password), {
        message: "Password must container at least one uppercase letter",
      })
      .refine((password) => /[a-z]/.test(password), {
        message: "Password must container at least one lowercase letter",
      })
      .refine((password) => /[0-9]/.test(password), { message: "Password must container at least one numeric digit" })
      .refine((password) => /[!@#$%^&*]/.test(password), {
        message: "Password must container at least one special character",
      }),
    confirmPassword: z.string().min(10).regex(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"), {
      message: "Password must be at least 10 characters and contain an uppercase letter, lowercase letter, and number",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash") || "";
  const isExecutingBrokerClient = searchParams.get("isEBClient") === "true";
  const [isSuccess, setIsSuccess] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const onSubmit = async (data: FormData) => {
    setApiErrorMessage("");
    const result = await dispatch(
      handlePasswordChange({
        password: data.password,
        hash,
      })
    );
    if (result.meta.requestStatus === "fulfilled") {
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setIsSuccess(false);
      setApiErrorMessage("The password reset link is invalid or has expired. Please request a new link.");
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  return (
    <Container component="main" maxWidth="xs" onKeyUp={handleEnterPress}>
      <AppBar dashboard={false} />
      <Box
        sx={{
          pt: 8,
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} spacing={5}>
          <Stack alignItems={"center"} spacing={1}>
            <Typography
              component="h1"
              variant="h4"
              fontSize="44px"
              fontWeight="bold"
              color={themeColors.text.secondary}
            >
              Welcome to InTick!
            </Typography>
            <Typography fontWeight={500} fontSize={18} color={themeColors.text.secondary}>
              Create a password to activate your account.
            </Typography>
          </Stack>
          <Fade in timeout={3000}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack alignItems={"center"} spacing={3} width={430}>
                <TextField
                  {...register("password")}
                  margin="normal"
                  label="Create Password"
                  fullWidth
                  error={!!errors.password}
                  type={showPassword ? "text" : "password"}
                  helperText={errors.password?.message}
                  InputLabelProps={{ shrink: true }}
                  size={"small"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          color="secondary"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  label="Repeat Password"
                  fullWidth
                  {...register("confirmPassword")}
                  error={!!errors.confirmPassword}
                  type={showPassword ? "text" : "password"}
                  helperText={errors.confirmPassword?.message}
                  InputLabelProps={{ shrink: true }}
                  size={"small"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          color="secondary"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack flexDirection={"row"} justifyContent={"space-between"} width="100%">
                  {isExecutingBrokerClient ? null : (
                    <Typography
                      maxWidth={270}
                      component="p"
                      variant="caption"
                      fontSize="10px"
                      color={themeColors.text.secondary}
                    >
                      By signing in, and creating and account you are agreeing to our{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={"https://www.intick.com/terms"}>
                        Terms of Use
                      </Link>{" "}
                      and{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={"https://www.intick.com/privacy-policy"}>
                        Privacy Policy
                      </Link>
                    </Typography>
                  )}
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    size={"small"}
                    variant="contained"
                    color={isSuccess ? "success" : "rfqDark"}
                    sx={{ fontSize: 16, px: 2.5 }}
                  >
                    {/* Span protects against a bug involving google translate */}
                    {isSuccess ? <CheckCircleIcon /> : <span>Done</span>}
                  </LoadingButton>
                </Stack>
              </Stack>{" "}
              {apiErrorMessage && (
                <Typography variant="caption" color={themeColors.error.primary}>
                  {apiErrorMessage}
                </Typography>
              )}
            </form>
          </Fade>
        </Stack>
      </Box>
      <ContactFooter />
    </Container>
  );
}
