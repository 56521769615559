export type FormData = {
  quantity: number;
  limitPrice: string;
  residual: boolean;
  broker: string;
  TIF: string;
};

export enum Residual {
  Yes = "Yes",
  No = "No",
}
