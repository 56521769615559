import { baseApi } from "@/config/BaseQuery";
import { BrokerDTO } from "@/features/auth/authApiSlice";

const clientsApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskDeskBrokers: build.query<BrokerDTO[], void>({
      query: () => `/brokers`,
      providesTags: ["RiskDeskBrokers"],
    }),
  }),
});

export const { useGetRiskDeskBrokersQuery } = clientsApiSlice;
