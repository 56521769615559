import { baseApi } from "@/config/BaseQuery";
import { MatchRequestDTO, OrderMatchRequestState } from "../orders/match/matchApiSlice";

export enum MatchRequestType {
  MATCH_REQUEST = "MatchRequest",
}

const matchRequestApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMatchRequests: build.query<MatchRequestDTO[], void>({
      query: () => `/order-match-requests?state=${OrderMatchRequestState.Pending}`,
      providesTags: ["MatchRequestNotifications"],
      transformResponse: (response: MatchRequestDTO[]) => {
        // Sort response such that those closest to expiration are first.
        return response.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      },
    }),
  }),
});

export const { useGetMatchRequestsQuery } = matchRequestApiSlice;
