import { InstrumentDTO } from "../instruments/instrumentsApiSlice";

interface LivePrice {
  ceiling: number;
  floor: number;
}

const mockedLivePrice = (instrument: InstrumentDTO | null): LivePrice => {
  if (!instrument) {
    return {
      ceiling: 0,
      floor: 0,
    };
  }
  const instrumentId = instrument.instrument_id;
  if (instrumentId === "VGH5VGM5") {
    return {
      ceiling: 4981.7,
      floor: 4981.45,
    };
  }

  if (instrumentId === "CAH5CAM5") {
    return {
      ceiling: 146.35,
      floor: 146.33,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrumentId === "OEH5OEM5") {
    return {
      ceiling: 106.61,
      floor: 106.6,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrumentId === "SXOH5M5") {
    return {
      ceiling: 518.29,
      floor: 518.27,
    };
  }

  if (instrumentId === "DUH5DUM5") {
    return {
      ceiling: 110.59,
      floor: 110.585,
    };
  }

  if (instrumentId === "FSMI-H5M5") {
    return {
      ceiling: 11719,
      floor: 11718,
    };
  }

  if (instrumentId === "G-H5G-M5") {
    return {
      ceiling: 689.5,
      floor: 689.4,
    };
  }

  if (instrumentId === "RXH5RXM5") {
    return {
      ceiling: 133.7,
      floor: 133.69,
    };
  }

  if (instrumentId === "OEH5OEM5") {
    return {
      ceiling: 128.09,
      floor: 128.08,
    };
  }

  if (instrumentId === "IKH5IKM5") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (instrumentId === "OATH5M5") {
    return {
      ceiling: 121.5,
      floor: 121.49,
    };
  }

  if (instrumentId === "BTSH5M5") {
    return {
      ceiling: 107.46,
      floor: 107.45,
    };
  }

  if (instrumentId === "UBH5UBM5") {
    return {
      ceiling: 135.41,
      floor: 135.39,
    };
  }
  return {
    ceiling: 0,
    floor: 0,
  };
};

export default mockedLivePrice;
