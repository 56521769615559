import { themeColors } from "@/app/theme";
import LoginFooter from "@/components/LoginFooter";
import AppBar from "@/features/dashboard/components/AppBar";
import { useAppDispatch } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import z from "zod";
import {
  fetchUser,
  handleLogin,
  selectAuthErrorCode,
  selectAuthStatus,
  selectAuthToken,
  selectAuthUser,
} from "../auth/authSlice";

const schema = z.object({
  email: z
    .string()
    .email({ message: "Email is required" })
    .min(5, { message: "Email must be at least 5 characters long" }),
  password: z
    .string({ message: "Password is required" })
    .min(10, { message: "Password must be at least 10 characters long" }),
  rememberMe: z.boolean(),
});
type FormData = z.infer<typeof schema>;

export default function Login() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const redirect: string = location.state?.from?.pathname || undefined;

  const authToken = useSelector(selectAuthToken);
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);
  const errorStatusCode = useSelector(selectAuthErrorCode);
  useEffect(() => {
    if (authToken && !authUser && authStatus === "idle") {
      // Once the token has been received, fetch the user.
      dispatch(fetchUser(authToken));
    }
  }, [authToken, authUser, authStatus]);

  const onSubmit = async (data: FormData) => {
    setApiErrorMessage("");
    const result = await dispatch(handleLogin(data));
    if (result.meta.requestStatus === "fulfilled") {
    } else {
      setApiErrorMessage("Invalid username or password, please try again");
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  if (authToken && authUser && authStatus === "idle") {
    return <Navigate to={redirect ? (redirect !== "/login" ? redirect : "/dashboard") : "/dashboard"} />;
  }

  return (
    <Container component="main" maxWidth="xs" onKeyUp={handleEnterPress}>
      <AppBar dashboard={false} />
      <Box
        sx={{
          pt: 8,
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} spacing={1} width={330}>
          <Stack alignItems={"center"} spacing={1}>
            <Typography component="h1" variant="h4" fontWeight={"bold"}>
              Login
            </Typography>
            <Typography fontWeight={500} fontSize={18}>
              Sign in to your account to get started.
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("email")}
              margin="normal"
              label="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
              InputLabelProps={{ shrink: true }}
              size={"small"}
              autoComplete="email"
              autoFocus
              fullWidth
            />
            <TextField
              {...register("password")}
              margin="normal"
              label="Password"
              autoComplete="password"
              error={!!errors.password}
              type={showPassword ? "text" : "password"}
              helperText={errors.password?.message}
              fullWidth
              InputLabelProps={{ shrink: true }}
              size={"small"}
              FormHelperTextProps={{
                sx: { color: themeColors.error.primary, mt: 1 },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color="secondary"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {apiErrorMessage && (
              <Typography variant="caption" color={themeColors.error.primary}>
                {apiErrorMessage}
              </Typography>
            )}
            <FormControlLabel
              sx={{ alignSelf: "flex-start" }}
              control={
                <Checkbox {...register("rememberMe")} checked={watch("rememberMe")} color="info" size={"small"} />
              }
              label="Remember me"
            />

            <Stack>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                size={"small"}
                variant="contained"
                color={"rfqDark"}
                sx={{ width: 120, mt: 3, mb: 2, ml: 14, alignSelf: "flex-end" }}
              >
                {/* Span protects against a bug involving google translate */}
                <span>Sign In</span>
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </Box>
      <LoginFooter />
    </Container>
  );
}
