import { themeColors } from "@/app/theme";
import { OrderState } from "@/features/orders/order/ordersApiSlice";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import { ParentOrderDTO } from "../parentApiSlice";

export interface MoreDetailsProps {
  parent: ParentOrderDTO;
}

export default function MoreDetails(props: MoreDetailsProps) {
  const { isLargeOrUp } = useBreakpoint();

  const tradeable = (props.parent.quantities.tradable || 0).toString();

  const working = (props.parent.quantities.working || "---").toString();

  const matched = (props.parent.quantities.matched || "---").toString();

  const chipStyles = {
    fontSize: "10px",
    fontWeight: "600",
    height: "22px",
    width: "75px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Stack direction="row" spacing={isLargeOrUp ? 1 : 0.2}>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Remaining" sx={{ ...chipStyles, backgroundColor: "#C0C0C0" }} />
        <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
          {numericFormatter(String(tradeable), { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Working" sx={{ ...chipStyles, backgroundColor: themeColors.state[OrderState.Working] }} />
        <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
          {numericFormatter(String(working), { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Matched" sx={{ ...chipStyles, backgroundColor: themeColors.state[OrderState.Matched] }} />
        <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
          {numericFormatter(String(matched), { thousandSeparator: "," })}
        </Typography>
      </Stack>
    </Stack>
  );
}
