import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InstrumentDTO } from "./instrumentsApiSlice";

export interface InstrumentSliceState {
  instrument: InstrumentDTO | null;
  instruments: InstrumentDTO[];
}

const initialState: InstrumentSliceState = {
  instrument: null,
  instruments: [],
};

export const instrumentSlice = createAppSlice({
  name: "instrument",
  initialState,
  reducers: (create) => ({
    setInstrument: create.reducer((state, action: PayloadAction<InstrumentDTO | null>) => {
      state.instrument = action.payload;
    }),
    setInstruments: create.reducer((state, action: PayloadAction<InstrumentDTO[]>) => {
      state.instruments = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectInstrumentByName: (state, name: string) => {
      if (!state.instruments.length) {
        return null;
      }
      const instrument = state.instruments.find((i) => i.name === name);
      return instrument || null;
    },
    selectInstrument: (state) => state.instrument,
    selectInstruments: (state) => state.instruments,
  },
});

// export const getInstrumentByName = (name: string) => (store: AppStore) => {
//   return store.getState().instrument.instruments.find((instrument) => instrument.name === name);
// };
// export const getInstrumentById = (id: string) => (store: AppStore) => {
//   return store.getState().instrument.instruments.find((instrument) => instrument.instrument_id === id);
// };

export const selectTerms = (instrument: InstrumentDTO | null) => {
  if (!instrument) {
    return ["", ""];
  }
  const start = instrument.term.slice(0, 5);
  const end = instrument.term.slice(5, instrument.term.length);
  return [start, end];
};

export const { setInstrument, setInstruments } = instrumentSlice.actions;

export const { selectInstrument, selectInstruments } = instrumentSlice.selectors;
