import { themeColors, themeVariants } from "@/app/theme";
import ErrorCard from "@/components/ErrorCard";
import { selectInstruments, setInstrument } from "@/features/instruments/instrumentSlice";
import { setActiveRfq } from "@/features/notifications/notificationSlice";
import { resetItems, setElectronicPrices, setPrice } from "@/features/orderbook/pricesSlice";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import ClearIcon from "@mui/icons-material/Clear";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { InstrumentDTO } from "@/features/instruments/instrumentsApiSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";
import { useSelector } from "react-redux";
import { ParentOrderDTO, useGetParentOrderQuery } from "../parentApiSlice";
import { selectParent, setParentOrder } from "../parentSlice";
import CancelParentOrder from "./CancelParentOrder";
import FlagIcon from "./FlagIcon";
import MoreDetails from "./MoreDetails";
import SendRfqDialog from "./SendRfqDialog";

export interface ParentOrderCardProps {
  order: ParentOrderDTO;
}

const FLAG_HEIGHT = 30.4;

export default function ParentOrderCard(props: ParentOrderCardProps) {
  const { isLargeOrUp, isMediumOrUp } = useBreakpoint();

  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);
  const instruments = useSelector(selectInstruments);

  const [isActive, setIsActive] = useState(selectedParent?.parent_order_id === props.order.parent_order_id);
  const [instrument, setLocalInstrument] = useState<InstrumentDTO | null>(null);

  useEffect(() => {
    const item = instruments.find((i) => i.instrument_id === props.order.instrument_id);
    setLocalInstrument(item || null);
  }, [instruments]);

  useEffect(() => {
    setIsActive(selectedParent?.parent_order_id === props.order.parent_order_id);
    dispatch(resetItems());
  }, [selectedParent]);

  const {
    currentData: parentOrderResponse,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetParentOrderQuery(props.order.parent_order_id, {
    // Refetch parent data every minute to check for aggregation updates.
    // This is syncrhonised with the child update schedule.
    pollingInterval: refetchTimeouts.fast,
  });

  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    if (parentOrderResponse && parentOrderResponse.quantities) {
      // If the status of this parent's child orders is any of that layed out in
      // the matureStates variable, then the parent is not cancellable.
      // const childStates = Object.keys(parentOrderResponse.quantities);
      // TODO:
      // setIsCancelable(childStates.every((childState) => !matureStates.includes(childState)));
    }
    if (isActive && parentOrderResponse) {
      // Make sure the store data for the selected parent is up-to-date.
      dispatch(setParentOrder(parentOrderResponse));
      dispatch(setPrice(null));
    }
  }, [parentOrderResponse]);

  // RFQ modal starts off closed
  const [rfqDialogOpen, setRfqDialogOpen] = useState(false);

  const closeTooltip = !isActive ? "Cancel order" : "Close";

  const handleClickPaper = () => {
    if (isCancel || !parentOrderResponse) return;
    dispatch(setParentOrder(isActive ? null : parentOrderResponse));
    dispatch(setPrice(null));
    if (!isActive) {
      // On select, set the underlying to == this parent's underlying.
      const poInstrument = instruments.find((i) => i.instrument_id === parentOrderResponse.instrument_id);
      dispatch(setInstrument(poInstrument || null));
      // and force the RFQ response state to be falsy.
      dispatch(setActiveRfq(null));
    } else {
      // On deselct, reset the selected price.
      dispatch(setPrice(null));
      // When a parent card is deselected, the instrument selection dropdown must default back to its null value.
      dispatch(setInstrument(null));
      // Force the electronic prices to reset.
      dispatch(
        setElectronicPrices({
          floor: 0,
          ceiling: 0,
        })
      );
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Causes button click to select its parent card but not deselect it if already selected.
    if (isActive) {
      event.stopPropagation();
    }
    setRfqDialogOpen(true);
  };

  const handleCloseRfqDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setRfqDialogOpen(false);
  };

  const handleCancelOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isActive) {
      // Deselect the card to trigger the collapse animation
      handleClickPaper();
    } else {
      setIsCancel(true);
    }
  };

  const { smallParentCard } = useBreakpoint();

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading || !instrument) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess && parentOrderResponse) {
    const tradeable = (parentOrderResponse.quantities.tradable || 0).toString();

    const working = (parentOrderResponse.quantities.working || "---").toString();

    const matched = (parentOrderResponse.quantities.matched || "---").toString();
    // const currencySymbol = "£";
    return (
      <Paper
        data-testid={`parent-order-${props.order.parent_order_id}`}
        elevation={1}
        sx={{
          "borderRadius": isLargeOrUp ? 4 : 2,
          "border": !isActive ? `0.3px solid ${themeColors.border}` : null,
          "py": 1,
          "pl": 2,
          "pr": 2,
          "mb": 2,
          "mx": 1,
          "backgroundColor": isCancel
            ? themeColors.white.secondary
            : isActive
            ? themeVariants.selected.backgroundColor
            : themeVariants.unselected.backgroundColor,
          "color": isActive ? themeVariants.selected.color : themeVariants.unselected.color,
          "transitionDuration": 400,
          "transitionTimingFunction": "ease",
          "transitionProperty": "all",
          "cursor": "pointer",
          "&:hover": {
            backgroundColor: !isActive && !isCancel ? themeColors.white.secondary : null,
          },
        }}
        onClick={() => handleClickPaper()}
      >
        {isCancel ? (
          <CancelParentOrder handleClose={() => setIsCancel(false)} id={props.order.parent_order_id} />
        ) : smallParentCard ? (
          <Stack sx={{ p: 0, ml: -1, mr: 1 }}>
            <Stack direction="row">
              <FlagIcon height={FLAG_HEIGHT} instrumentId={instrument.instrument_id} />
              <Typography fontSize={18} sx={{ flex: 1, ml: 1, mb: -1 }}>
                {instrument.name} - {numericFormatter(String(parentOrderResponse.quantity), { thousandSeparator: "," })}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ mt: 1, mb: 1 }}>
              <Typography fontSize={10} sx={{ flex: 1, mr: 1, mb: -1 }}>
                Remaining: {numericFormatter(String(tradeable), { thousandSeparator: "," })}
              </Typography>
              <Typography fontSize={10} sx={{ flex: 1, mr: 1, mb: -1 }}>
                Working: {numericFormatter(String(working), { thousandSeparator: "," })}
              </Typography>
              <Typography fontSize={10} sx={{ flex: 1, mb: -1 }}>
                Matched: {numericFormatter(String(matched), { thousandSeparator: "," })}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ height: 110 }}>
            <Stack direction="row" sx={{ alignItems: "center" }} justifyContent="space-between" mb={1}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: isLargeOrUp ? "block" : "none" }} pt={1} mr={1}>
                  <FlagIcon height={FLAG_HEIGHT} instrumentId={instrument.instrument_id} />
                </Box>
                <Box>
                  <Typography fontSize={18} fontWeight={600} sx={{ flex: 1, mb: 0 }}>
                    {instrument.name}
                  </Typography>
                  <Typography fontSize={12}>{instrument.term}</Typography>
                </Box>
              </Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                {isActive && (
                  <>
                    <Button
                      startIcon={<img src="./send-rfq.svg" />}
                      variant="outlined"
                      onClick={handleButtonClick}
                      color={isActive ? "rfqLight" : "rfqDark"}
                      sx={{ fontSize: 12, height: 25, minWidth: 10, paddingRight: 1 }}
                    >
                      {isLargeOrUp ? "Send RFQ" : null}
                    </Button>
                    <SendRfqDialog parent={parentOrderResponse} open={rfqDialogOpen} onClose={handleCloseRfqDialog} />
                  </>
                )}
                {/* TODO add rfq response company name */}
                {/* {!!parentOrderResponse?.rfq_company_name && (
                  <Typography variant="caption">{`Client: ${parentOrderResponse}`}</Typography>
                )} */}
                {/* {hasNotifications && (
                  <Badge role="notifications-badge" variant="dot" overlap="circular" color="secondary">
                    <NotificationsOutlinedIcon />
                  </Badge>
                )} */}

                <Tooltip title={closeTooltip} placement="top">
                  <IconButton onClick={handleCancelOrder} sx={{ height: 28, width: 28 }}>
                    <ClearIcon
                      sx={{
                        height: 20,
                        width: 20,
                        color: isActive ? themeVariants.selected.color : themeVariants.unselected.color,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Stack direction="row" mr={2}>
                <Stack justifyContent="space-between">
                  <Typography variant="caption" mb={0.5}>
                    Side
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: isMediumOrUp ? 20 : 14 }} fontWeight={500}>
                    {capitalize(parentOrderResponse.direction)}
                  </Typography>
                </Stack>
                <Stack sx={{ ml: isMediumOrUp ? 4 : 1.5 }} justifyContent="space-between">
                  <Typography variant="caption" mb={0.5}>
                    Quantity
                  </Typography>
                  <Typography sx={{ fontSize: isMediumOrUp ? 20 : 14 }} variant="numeric_body1" fontWeight={500}>
                    {numericFormatter(String(parentOrderResponse.quantity), {
                      thousandSeparator: ",",
                    })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <MoreDetails parent={parentOrderResponse} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Paper>
    );
  }

  return null;
}
