import { baseApi } from "@/config/BaseQuery";
import { Direction } from "../orders/parent/parentApiSlice";

export interface PostRfqPayload {
  parent_order_id: string;
  quantity: number;
  show_direction: boolean;
  broker_ids: string[];
}

export enum RfqState {
  Open = "OPEN",
  Closed = "CLOSED",
  Cancelled = "CANCELLED",
}

export enum RfqResponseState {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  RejectedByRequester = "REJECTED_BY_REQUESTER",
  RejectedByResponder = "REJECTED_BY_RESPONDER",
  Expired = "EXPIRED",
}
export interface RfqDTO {
  rfq_id: string;
  parent_order_id: string;
  quantity: number;
  state: RfqState;
  instrument_id: string;
  direction: Direction;
  show_direction: boolean;
  broker_ids: string[];
  created_by: string;
  responses_ids: string[];
  created_by_company_name: string;
  expires_in_seconds: number;
  expired_at: string;
  created_at: string;
  updated_at: string;
}

export interface PostRfqResponse {
  rfq_id: string;
  // "residual": boolean;
  direction: Direction;
  quantity: number;
  limit_price: number;
}

export interface RfqResponseDTO {
  rfq_response_id: string;
  limit_price: number;
  quantity: number;
  company_name: string;
  created_by_user_id: string;
  state: RfqResponseState;
  direction: Direction;
  rfq_id: string;
  expires_in_seconds: string;
  expired_at: string;
  created_at: string;
  updated_at: string;
}

export interface PatchRfqResponsePayload {
  rfq_id: string;
  rfq_response_id: string;
}

const rfqApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRfqs: build.query<RfqDTO[], void>({
      query: () => `/rfqs?state=${RfqState.Open}`,
      providesTags: ["Rfqs"],
      // todo uncomment
      // transformResponse: (response: RfqDTO[]) => {
      //   // Sort response such that those closest to expiration are first.
      //   return response.sort((a, b) => a.expires_in_ - b.expired_at);
      // },
    }),
    getRfqResponses: build.query<RfqResponseDTO[], string>({
      query: (instrumentId) => `/rfqs/all/responses?instrument_id=${instrumentId}&state=${RfqResponseState.Pending}`,
      providesTags: ["RfqResponses"],
    }),
    createRfq: build.mutation<RfqDTO, PostRfqPayload>({
      query: (body) => ({
        url: "/rfqs",
        method: "POST",
        body,
      }),
    }),
    createRfqResponse: build.mutation<RfqResponseDTO, PostRfqResponse>({
      query: ({ rfq_id, ...rest }) => ({
        url: `/rfqs/${rfq_id}/responses`,
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["ParentOrders", "Orders", "Rfqs", "RfqResponses"],
    }),
    rejectRfq: build.mutation<RfqDTO, string>({
      query: (id) => ({
        url: `/rfqs/${id}/responses`,
        method: "POST",
        body: { state: RfqResponseState.RejectedByResponder },
      }),
      invalidatesTags: ["Rfqs"],
    }),
    acceptRfq: build.mutation<RfqDTO, PatchRfqResponsePayload>({
      query: ({ rfq_id, rfq_response_id }) => ({
        url: `/rfqs/${rfq_id}/responses/${rfq_response_id}`,
        method: "PATCH",
        body: { state: RfqResponseState.Accepted },
      }),
      invalidatesTags: ["SelectedOrder", "SelectedParentOrder", "MyOrders", "Orders", "Rfqs", "RfqResponses"],
    }),
  }),
});

export const {
  useGetRfqsQuery,
  useCreateRfqMutation,
  useCreateRfqResponseMutation,
  useRejectRfqMutation,
  useGetRfqResponsesQuery,
  useAcceptRfqMutation,
} = rfqApiSlice;
