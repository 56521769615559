import { themeColors } from "@/app/theme";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { DEFAULT_BROKER } from "@/utils/Constants";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { NumericFormat, numericFormatter } from "react-number-format";
import { selectAuthUser } from "../auth/authSlice";
import { InstrumentDTO } from "../instruments/instrumentsApiSlice";
import { selectInstruments, selectTerms } from "../instruments/instrumentSlice";
import { selectActiveRfq, setActiveRfq } from "../notifications/notificationSlice";
import {
  RfqDTO,
  RfqResponseDTO,
  useAcceptRfqMutation,
  useCreateRfqResponseMutation,
} from "../notifications/rfqApiSlice";
import {
  isRfqResponse,
  resetItems,
  selectIsEmpty,
  selectItems,
  selectPrice,
  selectPriceInfo,
  setPrice,
} from "../orderbook/pricesSlice";
import { PostMatchRequest, useRequestMatchMutation } from "../orders/match/matchApiSlice";
import { CreateOrderError, OrderDTO, PostOrderPayload, useCreateOrderMutation } from "../orders/order/ordersApiSlice";
import { Direction } from "../orders/parent/parentApiSlice";
import { selectParent } from "../orders/parent/parentSlice";
import ErrorLabel from "./components/ErrorLabel";
import LimitPriceInput from "./components/LimitPriceInput";
import RfqDetails from "./components/RfqDetails";
import { Residual } from "./types";

const SummaryBox: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedPrice = useAppSelector(selectPrice);
  const instruments = useAppSelector(selectInstruments);
  const priceInfo = useAppSelector(selectPriceInfo);
  const selectedItems = useAppSelector(selectItems);
  const selectedParent = useAppSelector(selectParent);
  const isOffersResponseEmpty = useAppSelector(selectIsEmpty);
  const user = useAppSelector(selectAuthUser);
  const activeRfq = useAppSelector(selectActiveRfq);

  const [total, setTotal] = useState(0);
  const [minBlockSize, setMinBlockSize] = useState(0);
  const [isResidual, setIsResidual] = useState<Residual>(Residual.No);
  const [tier, setTier] = useState(99);

  const [limitPrice, setLimitPrice] = useState<number | null>(selectedPrice);

  const isPassive = !selectedItems.length;
  const isWarmStart = !!selectedParent && !!selectedPrice;
  const isColdStart = !!selectedParent && isOffersResponseEmpty;
  const isRfq = activeRfq !== null;
  const tradeableTotal = selectedParent?.quantities.tradable;
  const isWorkingOrderSelected =
    selectedItems.length === 1 &&
    !!selectedParent &&
    (selectedItems[0] as OrderDTO).parent_order_id === selectedParent.parent_order_id;

  const [isOpen, setIsOpen] = useState(
    ((isWarmStart || isColdStart || isRfq) && tradeableTotal !== 0) || isWorkingOrderSelected
  );

  useEffect(() => {
    setIsOpen(((isWarmStart || isColdStart || isRfq) && tradeableTotal !== 0) || isWorkingOrderSelected);
    if (isWorkingOrderSelected) {
      setIsResidual((selectedItems[0] as OrderDTO)?.residual ? Residual.Yes : Residual.No);
    }
  }, [isWarmStart, isColdStart, isRfq, tradeableTotal, selectedItems, selectedParent]);

  // Default for RFQ response.
  useEffect(() => {
    if (!!activeRfq) {
      setIsResidual(Residual.Yes);
    }
  }, [activeRfq]);

  const [createOrder, { error: createOrderError, isLoading: isLoadingCreateOrder }] = useCreateOrderMutation();

  const [requestMatch, { error: requestMatchError, isLoading: isLoadingRequestMatch }] = useRequestMatchMutation();
  const [acceptRfqResponse] = useAcceptRfqMutation();

  const [createRfqResponse] = useCreateRfqResponseMutation();

  // const { data: envData, error: envError, isError: isErrorEnv } = useGetEnvironmentQuery();

  const [instrument, setInstruemnt] = useState<InstrumentDTO | null>(null);

  useEffect(() => {
    dispatch(resetItems());

    if (instruments) {
      const instrument =
        instruments.find(
          (i) => i.instrument_id === selectedParent?.instrument_id || i.instrument_id === activeRfq?.instrument_id
        ) || null;

      setInstruemnt(instrument);
      if (instrument) {
        setMinBlockSize(instrument.min_quantity);
      }
    }
  }, [selectedParent, activeRfq]);

  useEffect(() => {
    if (!!selectedItems.length) {
      const selectedItemsTotal = selectedItems
        .map((item) => item.quantity)
        .reduce((total, quantity) => total + quantity, 0);

      setTotal(selectedItemsTotal);
      return;
    }

    if (activeRfq !== null) {
      // When an RFQ is active set the summary box quantity to its quantity
      setTotal(activeRfq.quantity);
    } else {
      // When an RFQ is no longer active, reset the summary box quantity
      if (tradeableTotal !== undefined && tradeableTotal > 0) {
        // By default, set the quantity of a passive trade to equal the
        // remaining quantity of a parent.
        setTotal(tradeableTotal);
      } else {
        setTotal(0);
      }
    }
  }, [selectedItems, activeRfq, tradeableTotal]);

  const handleChangeResidualOk = (event: SelectChangeEvent) => {
    setIsResidual(event.target.value as Residual);
  };

  // const handleChangeTier = (event: SelectChangeEvent) => {
  //   setTier(parseInt(event.target.value));
  // };

  // If the summary box closes (i.e. by deselecting a price), the default
  // tier should be reset to 1.
  // Likewise isResidual should be reset to "No" (if not responding to an RFQ).
  useEffect(() => {
    if (!isOpen) {
      setTier(99);
      setIsResidual(isRfq ? Residual.Yes : Residual.No);
    }
  }, [isOpen]);

  // If the user selects a price card, update the value being tracked here.
  useEffect(() => {
    setLimitPrice(selectedPrice);
  }, [selectedPrice]);

  const handleSubmit = async () => {
    if (isRfq) {
      handleSubmitRfq();
      return;
    }
    if (!selectedParent || !selectedPrice || !instrument) return;
    if (!selectedItems.length) {
      // Todo check why typings dont work here
      const direction = activeRfq ? (activeRfq as RfqDTO).direction : selectedParent.direction;
      // Create a passive offer (a.k.a. a "working" order)
      const payload: PostOrderPayload = {
        parent_order_id: selectedParent.parent_order_id,
        quantity: total,
        limit_price: selectedPrice,
        residual: isResidual === Residual.Yes,
        instrument_id: instrument.instrument_id,
        direction,
        tier: tier,
      };
      try {
        await createOrder(payload).unwrap();
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (passive) Error: ", error);
      }
    } else {
      // Aggresively take one or more offers (a.k.a. create "waiting" orders)
      const payloads = selectedItems.map((item) =>
        isRfqResponse(item)
          ? {
              isRfqResponse: true,
              rfq_id: (item as RfqResponseDTO).rfq_id,
              rfq_response_id: (item as RfqResponseDTO).rfq_response_id,
            }
          : ({
              isRfqResponse: false,
              requesting_parent_order_id: selectedParent.parent_order_id,
              responding_order_id: (item as OrderDTO).order_id,
              quantity: (item as OrderDTO).quantity,
            } as PostMatchRequest)
      );
      try {
        const promises = payloads.map(({ isRfqResponse, rfq_id, rfq_response_id, ...rest }: any) =>
          isRfqResponse ? acceptRfqResponse({ rfq_id, rfq_response_id }) : requestMatch(rest)
        );
        await Promise.all(promises);
      } catch (error) {
        console.log("[ SummaryBox ] handleSubmit (aggresive) Error: ", error);
      } finally {
        resetGlobalPrice(); // Deselect the price.
        dispatch(resetItems()); // Deselect all offers.
      }
    }
  };

  const handleSubmitRfq = async () => {
    if (!isRfq || !user || selectedPrice === null) return;
    try {
      const payload = {
        rfq_id: activeRfq.rfq_id,
        // residual: isResidual === Residual.Yes,
        quantity: total,
        direction: selectedParent?.direction ?? activeRfq?.direction,
        limit_price: selectedPrice,
      };
      await createRfqResponse(payload).unwrap();
    } catch (error) {
      console.log("[ SummaryBox ] createRfqResponse error", error);
    } finally {
      resetGlobalPrice();
      dispatch(setActiveRfq(null));
    }
  };

  useEffect(() => {
    // Update the redux store with the limit price - this enables price ladder
    // updates if there are no offers in the market.
    if (isFormValid()) {
      dispatch(setPrice(limitPrice));
    }
  }, [limitPrice]);

  // const handleDismissRfq = () => {
  //   if (isRfq) {
  //     dispatch(setActiveRfq(null));
  //     if (!selectedParent) {
  //       dispatch(setInstrument(null));
  //     }
  //     setLimitPrice(null);
  //   }
  // };

  // const selectIsPriceInputDisabled = () => {
  //   if (!selectedItems.length) {
  //     return false;
  //   }
  //   if (selectedItems[0]?.state !== OrderState.Working) {
  //     return true;
  //   }
  //   return false;
  // };
  const resetGlobalPrice = () => {
    dispatch(setPrice(null));
  };

  const selectErrorMessage = () => {
    if (!instrument || isWorkingOrderSelected) return null;
    const error = createOrderError || requestMatchError || undefined;
    if (error && "data" in error) {
      const messages = (error.data as CreateOrderError)?.errors || [];
      // Grab the first error message, or use the default.
      return !!messages.length ? capitalize(messages[0]) : "An unknown error occurred.";
    }

    if (getIsTotalInvalid()) {
      return "Quantity exceeds the remaining quantity on the order.";
    }
    if (instrument && total < instrument.min_quantity) {
      return `The minimum block size is ${numericFormatter(instrument.min_quantity.toString(), {
        thousandSeparator: true,
      })}.`;
    }
    const remaining = Number(selectedParent?.quantities.tradable) - total;

    if (remaining > 0 && remaining < instrument.min_quantity) {
      return "This trade leaves a remainder below the block size!";
    }

    return null;
  };

  const getButtonColour = () => {
    if (activeRfq && activeRfq?.direction) {
      return activeRfq.direction === Direction.BUY ? Direction.SELL : Direction.BUY;
    }
    if (selectedParent?.direction === undefined) return "dark";
    return selectedParent?.direction;
  };

  const getIsTotalInvalid = () => {
    if (tradeableTotal !== undefined) {
      return total > tradeableTotal;
    }
    return false;
    // Not sure if this is a requirement or not.
    // if (isRfq) {
    //   return total > activeRfq.quantity;
    // }
  };

  const activeUnderlying = instrument?.name ?? "";
  const activeDirection = selectedParent?.direction ?? activeRfq?.direction ?? "";

  const activeQuantity = numericFormatter(total.toString(), {
    thousandSeparator: true,
  });
  const errorMessage = selectErrorMessage();
  const isFormValid = () => {
    if (total < minBlockSize || getIsTotalInvalid()) {
      return false;
    }
    if (limitPrice === null) {
      return false;
    }
    if (!Number.isInteger((limitPrice * 1000) / (priceInfo.step * 1000))) {
      return false;
    }
    return true;
  };

  const quantityInputError = () => {
    return total < minBlockSize || getIsTotalInvalid();
  };

  const getActiveDirection = () => {
    if (activeRfq && activeRfq?.direction) {
      return activeRfq.direction === Direction.BUY ? Direction.SELL : Direction.BUY;
    }
    if (selectedParent && selectedParent.direction) {
      return selectedParent.direction;
    }
    return "";
  };

  const selectSubmitButtonLabel = () => {
    if (getActiveDirection() !== "") {
      return capitalize(getActiveDirection());
    }
    return "Submit";
  };

  const [termStart, termEnd] = selectTerms(instrument);
  if (isOpen && !!user) {
    return (
      <Paper
        elevation={4}
        component="form"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleSubmit();
        }}
        sx={{
          zIndex: 1000,
          minWidth: !!activeRfq ? "60vw" : "50vw",
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: themeColors.white.primary,
          borderTop: `1px solid ${themeColors.border}`,
          borderRadius: "8px 0 0 8px",
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ pr: 2 }}>
          <Stack direction="row">
            {!!activeRfq && <RfqDetails />}
            <Stack sx={{ py: 4, pl: 2 }}>
              <Typography variant="h6" lineHeight={"130%"}>
                {activeUnderlying}
              </Typography>
              <Typography variant={"body1"} lineHeight={"130%"}>
                {instrument?.term}
              </Typography>
              {activeDirection !== "" && (
                <Typography
                  minWidth={150}
                  fontSize={10}
                  fontWeight={500}
                  lineHeight={"130%"}
                  letterSpacing={"0.5px"}
                  mt={1}
                >
                  {capitalize(getActiveDirection())} {activeQuantity} {activeUnderlying} {termStart} <br />
                  {capitalize(Object.values(Direction).find((value) => value !== getActiveDirection()))}{" "}
                  {activeQuantity} {activeUnderlying} {termEnd}
                </Typography>
              )}
            </Stack>
          </Stack>
          <IconButton sx={{ position: "absolute", top: 0, right: 0 }} onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>

          <Stack spacing={2} mr={1} alignItems="flex-end" py={5}>
            {/* Top row */}
            <Stack direction="row" height={40} spacing={1}>
              <NumericFormat
                // NumericFormat Props
                value={total}
                customInput={TextField}
                allowNegative={false}
                thousandSeparator
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    setTotal(values.floatValue);
                  } else {
                    setTotal(0);
                  }
                }}
                // MUI TextField Props
                sx={{ width: 128 }}
                disabled={selectedItems?.length !== 0}
                size="small"
                id="total-field"
                label="Quantity"
                error={selectedItems.length === 0 && quantityInputError()}
              />
              <LimitPriceInput
                value={limitPrice}
                setValue={setLimitPrice}
                onValueChange={(values) => {
                  if (values.floatValue !== undefined) {
                    if (!isColdStart && !isRfq) {
                      setLimitPrice(values.floatValue);
                    } else {
                      // TODO: this currently allows users to enter prices
                      // beyond the electronic tick when responding to an RFQ.
                      setLimitPrice(values.floatValue);
                    }
                  }
                }}
                disabled={!!selectedItems.length}
                isActionDisabled={!instruments || !!selectedItems.length}
              />
              {(isPassive || isWorkingOrderSelected) && !activeRfq && (
                <FormControl size="small" sx={{ width: 96 }}>
                  <InputLabel id="select-residual-label">Residual</InputLabel>
                  <Select
                    labelId="select-residual-label"
                    id="select-residual"
                    value={isResidual}
                    label="Residual"
                    disabled={isWorkingOrderSelected}
                    MenuProps={{ disablePortal: true }}
                    onChange={handleChangeResidualOk}
                  >
                    <MenuItem value={Residual.No}>No</MenuItem>
                    <MenuItem value={Residual.Yes}>Yes</MenuItem>
                  </Select>
                </FormControl>
              )}
              {!isWorkingOrderSelected && (
                <Badge
                  badgeContent={selectedItems.length}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="success"
                >
                  <LoadingButton
                    loading={isLoadingCreateOrder || isLoadingRequestMatch}
                    size="small"
                    variant="contained"
                    color={getButtonColour()}
                    sx={{ fontSize: 18, borderRadius: 2 }}
                    disabled={!isFormValid()}
                    data-testid="submit-order"
                    type="submit"
                  >
                    <span>{selectSubmitButtonLabel()}</span>
                  </LoadingButton>
                </Badge>
              )}
            </Stack>

            {/* Bottom Row */}
            {!isRfq && (
              <Stack direction="row" height={40} spacing={1}>
                {/* {isPassive && (
                  <FormControl size="small" sx={{ width: 128 }}>
                    <InputLabel id="select-tier-label">Tier</InputLabel>
                    <Select
                      labelId="select-tier-label"
                      id="select-tier"
                      value={tier.toString()}
                      label="Tier"
                      MenuProps={{ disablePortal: true }}
                      onChange={handleChangeTier}
                    >
                      <MenuItem value={99}>All</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </Select>
                  </FormControl>
                )} */}
                {(isPassive || isWorkingOrderSelected) && (
                  <>
                    <TextField
                      sx={{ width: 128 }}
                      disabled
                      size="small"
                      id="broker-field"
                      label="Broker"
                      value={DEFAULT_BROKER}
                      // value={user["default-broker"]}
                    />
                    <TextField sx={{ width: 96 }} disabled size="small" id="tif-field" label="TIF" value="Day" />
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <ErrorLabel message={errorMessage} />
      </Paper>
    );
  }

  return null;
};

export default SummaryBox;
