import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import { themeColors } from "@/app/theme";
import { selectInstrument } from "@/features/instruments/instrumentSlice";
import { OrderDTO, OrderState, orderStateLabels } from "@/features/orders/order/ordersApiSlice";
import { getDecimalScale } from "@/features/summary/components/LimitPriceInput";
import { useAppSelector } from "@/store/hooks";
import { metricFormatter } from "@/utils/String";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import CancelButton from "./CancelButton";
import Logo from "/favicon.svg";

export interface OrderCardProps {
  order: OrderDTO;
  index: number;
}

export default function OrderCard({ order, index }: OrderCardProps) {
  // const { data, error, isError, isLoading, isFetching, isSuccess, refetch } = useGetOrderQuery(id, {
  //   // Refetch child data every minute to check for status updates.
  //   pollingInterval: refetchTimeouts.fast,
  // });
  const instrument = useAppSelector(selectInstrument);
  const decimalScale = getDecimalScale(instrument?.tick_size);

  return (
    <Accordion
      expanded={false}
      // expanded={expanded === index}
      // onChange={handleChange(index)}
      elevation={4}
      disableGutters
      square={true}
      sx={{
        "mt": index === 0 ? 0 : "-24px",
        "borderRadius": 3,
        "zIndex": index,
        "&:before": {
          display: "none",
        },
        "backgroundColor": themeColors.state[order.state],
        "color": order.state === OrderState.BrokerFilled ? themeColors.white.primary : themeColors.text.primary,
        "&:hover": {
          filter: "brightness(0.96)",
        },
      }}
    >
      <AccordionSummary aria-controls={`order-${index}-content`} id={`order-${index}-header`}>
        <Stack sx={{ flex: 1, height: 80 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="h5" fontWeight={500}>
                {metricFormatter(order.quantity, 1)}
              </Typography>
              <Typography variant="h5">at</Typography>
              <Typography variant="h5">
                {numericFormatter(String(order.limit_price), {
                  fixedDecimalScale: true,
                  decimalScale,
                })}
              </Typography>
            </Stack>

            <img style={{ height: 20 }} src={Logo} alt="InTick Logo" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              {orderStateLabels[order.state]}
            </Typography>

            {[OrderState.Working, OrderState.AwaitingMatch].includes(order.state) ? (
              <CancelButton data={order} />
            ) : null}
          </Box>
        </Stack>
      </AccordionSummary>
      {/* <AccordionDetails>
          <Stack sx={{ flex: 1 }}>
            <Box
              alignItems="center"
              justifyContent="space-between"
              display="flex"
            >
              <Typography fontSize={12}>Working on screen</Typography>
              <Typography fontSize={12}>Manual replace</Typography>
            </Box>
          </Stack>
        </AccordionDetails> */}
    </Accordion>
  );
}
