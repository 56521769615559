import { themeColors } from "@/app/theme";
import { selectInstruments, setInstrument } from "@/features/instruments/instrumentSlice";
import { setPrice } from "@/features/orderbook/pricesSlice";
import { setParentOrder } from "@/features/orders/parent/parentSlice";
import useTimer from "@/hooks/useTimer";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import {
  Notification,
  getNotificationBody,
  getNotificationSender,
  getNotificationSubtitle,
  getNotificationTitle,
  getRfqDirection,
  isMatchRequest,
  isRfq,
  setActiveNotification,
  setActiveRfq,
} from "../notificationSlice";
import useNotificationMutations from "./useNotificationMutations";

export interface DetailsProps {
  notification: Notification;
  handleClose: () => void;
}

export default function NotifiactionDetails(props: DetailsProps) {
  const dispatch = useAppDispatch();
  const { handleAcceptMatch, isLoadingAccept, error } = useNotificationMutations();
  const instruments = useAppSelector(selectInstruments);
  const { timer, isTimerDone } = useTimer(props.notification !== null ? props.notification.expires_in_seconds : 0);

  const residual = numericFormatter(
    (isMatchRequest(props.notification) ? props.notification.residual_quantity : 0).toString(),
    {
      thousandSeparator: true,
    }
  );

  const instrument = instruments.find((i) => i.instrument_id === props.notification?.instrument_id);
  const handleAcceptAction = () => {
    if (isMatchRequest(props.notification)) {
      handleAcceptMatch(props.notification);
      props.handleClose();
    }
    if (isRfq(props.notification)) {
      // Deslect any parent order.
      dispatch(setParentOrder(null));

      // Reset the selected price.
      // Incompatible values between instruments will cause issues if this isn't done.
      dispatch(setPrice(null));

      // Inform the app about the RFQ details.
      dispatch(setActiveRfq(props.notification));

      // SummaryBox needs to know the instrument as it usually gets this info
      // from the parent order.
      const instrument = instruments.find((i) => i.instrument_id === props.notification?.instrument_id);
      if (instrument) {
        dispatch(setInstrument(instrument));
      }
      props.handleClose();
    }
  };

  return (
    <Stack
      px={3}
      py={1}
      mb={1}
      sx={{
        backgroundColor: themeColors.white.primary,
        borderTop: 1,
        borderBottom: 1,
        borderColor: themeColors.border,
      }}
    >
      {/* Client info */}
      <Box display="flex" justifyContent="flex-end">
        <Typography fontSize={10}>{getNotificationSender(props.notification)}</Typography>
      </Box>

      {/* Badge & Title */}
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <CircleIcon color="success" sx={{ fontSize: 12 }} />
        <Typography fontWeight={700}>{getNotificationTitle(props.notification, instrument)}</Typography>
      </Stack>

      {/* Info text */}
      <Typography ml={2} fontSize={12}>
        {getNotificationSubtitle(props.notification, instrument)}
      </Typography>

      {/* Match info */}
      <Grid container mt={1}>
        <Grid item xs={4}>
          <Stack>
            <Typography fontSize={10} textAlign="center">
              {isMatchRequest(props.notification) ? "Order details" : "Direction"}
            </Typography>
            <Typography textAlign="center" fontWeight={500}>
              {isMatchRequest(props.notification)
                ? getNotificationBody(props.notification, instrument)
                : getRfqDirection(props.notification)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography fontSize={10} textAlign="center">
              {isMatchRequest(props.notification) ? "Residual" : "Total quantity"}
            </Typography>
            <Typography textAlign="center" fontWeight={500}>
              {isMatchRequest(props.notification)
                ? residual
                : numericFormatter((props.notification?.quantity || 0).toString(), {
                    thousandSeparator: true,
                  })}
            </Typography>
            {isRfq(props.notification) && (
              <Typography fontSize={8} textAlign="center">
                You can edit this amount when trading
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography fontSize={10} textAlign="center">
              {isMatchRequest(props.notification) ? "Time Remaining" : "Time to action"}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="center">
              <AccessTimeIcon color="error" sx={{ fontSize: 14 }} fontWeight={500} />
              <Typography
                color="error"
                fontWeight={500}
                // Fixed timer width stops the clock from jumping around.
                // If the string's length is 5 === hh:mm format.
                width={timer.length === 5 ? 48 : 64}
              >
                {timer}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 1 }} />

      <Stack direction="row" justifyContent="center" flex={1} spacing={2}>
        <Button
          sx={{
            color: themeColors.black.secondary,
            fontSize: 14,
            borderRadius: 2,
          }}
          onClick={() => dispatch(setActiveNotification(null))}
        >
          Dismiss
        </Button>
        <LoadingButton
          loading={isLoadingAccept}
          disabled={isTimerDone}
          variant="contained"
          color="success"
          onClick={() => handleAcceptAction()}
          sx={{ fontSize: 18, borderRadius: 2 }}
        >
          <span>{isRfq(props.notification) ? "Respond" : "Accept"}</span>
        </LoadingButton>
      </Stack>
      <Typography fontSize={10} mt={!!error ? 1 : 0} color="error">
        {error}
      </Typography>
    </Stack>
  );
}
