import CookieConsent from "@/components/CookieConsent";
import ChatWithUsBtn from "@/components/buttons/ChatWithUsBtn";
import OrderBook from "@/features/orderbook/OrderBook";
import MyOrders from "@/features/orders/order/MyOrders";
import { selectParent } from "@/features/orders/parent/parentSlice";
import SummaryBox from "@/features/summary/SummaryBox";
import { useAppSelector } from "@/store/hooks";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setInstruments } from "../instruments/instrumentSlice";
import { useGetInstrumentsQuery } from "../instruments/instrumentsApiSlice";
import { selectActiveRfq } from "../notifications/notificationSlice";
import ParentOrders from "../orders/parent/ParentOrders";
import AppBar from "./components/AppBar";
import InstrumentSelect from "./components/InstrumentSelect";

export default function Dashboard() {
  const dispatch = useDispatch();
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);
  const { data } = useGetInstrumentsQuery();
  // Load instruments once before rendering the dashboard
  useEffect(() => {
    if (data?.length) {
      dispatch(setInstruments(data));
    } else {
      dispatch(setInstruments([]));
    }
  }, [data]);

  return (
    <>
      <CookieConsent />
      <AppBar dashboard />
      <Grid component="main" container spacing={2} sx={{ mt: 14 }}>
        <Grid item xs={4}>
          <Container maxWidth={false} disableGutters>
            <ParentOrders />
            <Stack spacing={1.5} sx={{ position: "fixed", bottom: 16, left: 16 }}>
              {/*GUIDANCE AND PRICING CHART BUTTONS DISABLED FOR MVP*/}
              {/* <GuidanceBtn /> */}
              {/* <PricingChartBtn /> */}
              <ChatWithUsBtn />
            </Stack>
          </Container>
        </Grid>
        <Grid item xs={8}>
          <Container maxWidth={false} disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <MyOrders />
              </Grid>
              <Grid item xs={8}>
                {!selectedParent && !activeRfq && <InstrumentSelect />}
                <OrderBook />
                <SummaryBox />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
