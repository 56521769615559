import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDeleteParentOrderMutation } from "../parentApiSlice";

export interface CancelParentOrderProps {
  handleClose: () => void;
  id: string;
}

export default function CancelParentOrder(props: CancelParentOrderProps) {
  const [deleteParentOrder, { error, isLoading, isError }] = useDeleteParentOrderMutation();

  const handleClickNo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.handleClose();
  };

  const handleClickYes = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    try {
      await deleteParentOrder(props.id).unwrap();
      props.handleClose();
    } catch (error) {
      console.log("[ CancelParentOrder ] handleClickYes ERROR: ", error);
    }
  };

  if (isLoading) {
    return (
      <Stack minHeight={96} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError && "data" in error) {
    return (
      <Stack minHeight={96} alignItems="center" justifyContent="center">
        <Alert severity="error" sx={{ flex: 1 }}>
          {`[${error.status}]: ${JSON.stringify(error.data)}`}
        </Alert>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" size="small" sx={{ fontSize: 18 }} onClick={handleClickNo}>
            Keep this order
          </Button>
          <Button variant="contained" color="error" size="small" sx={{ fontSize: 18 }} onClick={handleClickYes}>
            Cancel order
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack minHeight={96}>
      <Typography variant="h6">Are you sure you want to cancel this order?</Typography>
      <Typography variant="caption" mb={1}>
        This action will cancel your current order.
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" size="small" sx={{ fontSize: 18, borderRadius: 2 }} onClick={handleClickNo}>
          Keep this order
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{ fontSize: 18, borderRadius: 2 }}
          onClick={handleClickYes}
        >
          Cancel order
        </Button>
      </Stack>
    </Stack>
  );
}
