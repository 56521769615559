import ErrorCard from "@/components/ErrorCard";
import { refetchTimeouts } from "@/utils/Constants";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddOrderBtn from "./components/AddOrderBtn";
import ParentOrderCarousel from "./components/ParentOrderCarousel";
import { useGetParentOrdersQuery } from "./parentApiSlice";

export default function ParentOrders() {
  const { currentData, error, isError, isLoading, isSuccess, refetch } = useGetParentOrdersQuery(undefined, {
    // Regularly refetch parent orders to receive those staged via InfoReach
    pollingInterval: refetchTimeouts.fast,
  });

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess) {
    return currentData?.length ? (
      <>
        <ParentOrderCarousel orders={currentData}>
          <AddOrderBtn isFirstOrder={false} />
        </ParentOrderCarousel>
      </>
    ) : (
      <Box pl={2}>
        <Typography variant="h5" fontWeight={500} mb={1}>
          Getting Started
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          Load orders from your EMS by selecting "Intick," add
          <br />
          an order directly, or start trading to create an order.
        </Typography>
        <AddOrderBtn isFirstOrder={true} />
      </Box>
    );
  }

  return null;
}
