import { themeColors } from "@/app/theme";
import usePriceQueries from "@/features/orderbook/usePriceQueries";
import { Direction } from "@/features/orders/parent/parentApiSlice";
import { metricFormatter } from "@/utils/String";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const tickerCircle = (fillColor: string) => {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 3, marginBottom: 1 }}
    >
      <circle cx="3.5" cy="4" r="3.5" fill={fillColor} />
    </svg>
  );
};

export default function Ticker() {
  const { ordersData, rfqResponsesData, getBestPrice } = usePriceQueries();
  const orders = ordersData?.length ? [...ordersData] : [];
  const rfqResponses = rfqResponsesData?.length ? [...rfqResponsesData] : [];
  const data = [...orders, ...rfqResponses];
  const totalQuantityBuy = data
    .map((order) => (order.direction === Direction.BUY ? order.quantity : 0))
    .reduce((cur, acc) => acc + cur, 0);

  const totalQuantitySell = data
    .map((order) => (order.direction === Direction.SELL ? order.quantity : 0))
    .reduce((cur, acc) => acc + cur, 0);

  const bestBuy = getBestPrice(Direction.BUY, data ?? []) ?? 0;
  const bestSell = getBestPrice(Direction.SELL, data ?? []) ?? 0;
  // Placeholder value until API connection is available
  // const lastTradePrice: number = 0;

  return (
    <>
      <Stack direction={"row"} spacing={3}>
        <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Total Bid
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            {totalQuantityBuy < 1000
              ? totalQuantityBuy.toString().padStart(3, "0")
              : metricFormatter(totalQuantityBuy, 1)}
          </Typography>
          <Typography color={themeColors.prices[Direction.BUY]} fontSize={14} fontWeight={600}>
            {tickerCircle(themeColors.prices[Direction.BUY])}
            {bestBuy === 0 ? "00000" : bestBuy.toFixed(3)}
          </Typography>
        </Stack>
        <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Total Ask
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            {totalQuantitySell < 1000 ? bestSell.toString().padStart(3, "0") : metricFormatter(totalQuantitySell, 1)}
          </Typography>
          <Typography color={themeColors.prices[Direction.SELL]} fontSize={14} fontWeight={600}>
            {tickerCircle(themeColors.prices[Direction.SELL])}
            {bestSell === 0 ? "00000" : bestSell.toFixed(3)}
          </Typography>
        </Stack>
        {/* <Stack spacing={-0.5} sx={{ width: 80 }}>
          <Typography color={themeColors.black.primary} fontSize={14}>
            Last Trade
          </Typography>
          <Typography color={themeColors.black.primary} fontSize={24}>
            000
          </Typography>
          <Typography
            color={themeColors.success.primary}
            fontSize={14}
            fontWeight={600}
          >
            {tickerCircle(themeColors.success.primary)}
            {lastTradePrice === 0 ? "00000" : lastTradePrice.toString()}
          </Typography>
        </Stack> */}
      </Stack>
    </>
  );
}
