import { MatchRequestDTO, useAcceptMatchMutation, useRejectMatchMutation } from "@/features/orders/match/matchApiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { RfqDTO, useRejectRfqMutation } from "../rfqApiSlice";

export default function useNotificationMutations() {
  const [rejectMatch, { isLoading: isLoadingReject }] = useRejectMatchMutation();

  const handleRejectMatch = async (req: MatchRequestDTO) => {
    try {
      await rejectMatch(req.order_match_request_id).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleRejectMatch error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [acceptMatch, { isLoading: isLoadingAccept }] = useAcceptMatchMutation();

  const handleAcceptMatch = async (req: MatchRequestDTO) => {
    try {
      await acceptMatch(req.order_match_request_id).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleAcceptMatch error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [dismissRfq, { isLoading: isLoadingDismiss }] = useRejectRfqMutation();

  const handleDismissRfq = async (n: RfqDTO) => {
    try {
      await dismissRfq(n.rfq_id).unwrap();
    } catch (error) {
      console.log("[ useNotificationMutations ] handleDissmissRfq error: ", error);
      if (typeof error === "object" && !!error && "data" in error) {
        handleNotificationError(error as FetchBaseQueryError);
      }
    }
  };

  const [error, setError] = useState("");
  const handleNotificationError = (error: FetchBaseQueryError) =>
    setError(`[${error.status}] ${error.data ? JSON.stringify(error.data) : "An unknown error occured."}`);

  return {
    handleRejectMatch,
    isLoadingReject,
    handleAcceptMatch,
    isLoadingAccept,
    handleDismissRfq,
    isLoadingDismiss,
    error,
  };
}
