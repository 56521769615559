import ContactFooter from "@/components/ContactFooter";
import AppBar from "@/features/dashboard/components/AppBar";
import { useAppDispatch } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import z from "zod";
import { handlePasswordReset } from "../auth/authSlice";

const schema = z.object({
  email: z.string().email({ message: "Email is required" }),
});
type FormData = z.infer<typeof schema>;

export default function ResetPassword() {
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: FormData) => {
    const result = await dispatch(handlePasswordReset(data.email));
    if (result.meta.requestStatus === "fulfilled") {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <AppBar dashboard={false} />
      <Box
        sx={{
          pt: 8,
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} spacing={3}>
          <Stack alignItems={"center"} spacing={1}>
            <Typography component="h1" variant="h4" fontWeight={"bold"}>
              Intick
            </Typography>
            <Typography fontWeight={500} fontSize={18}>
              Please enter your account email to reset your password.
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems={"center"} spacing={3} width={430}>
              <TextField
                margin="normal"
                label="Email"
                fullWidth
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputLabelProps={{ shrink: true }}
                size={"small"}
              />

              <LoadingButton
                loading={isSubmitting}
                type="submit"
                size={"small"}
                variant="contained"
                color={isSuccess ? "success" : "rfqDark"}
                fullWidth
              >
                {isSuccess ? <CheckCircleIcon /> : <span>Reset</span>}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </Box>
      <ContactFooter />
    </Container>
  );
}
