import { baseApi } from "@/config/BaseQuery";

export interface BrokerDTO {
  company_id: string;
  full_name: string;
  name: string;
}

export interface CompanyDTO {
  company_id: string;
  name: string;
  full_name: string;
  ems_identifier: string;
  account: string;
  roles: string[];
  brokers: BrokerDTO[];
  default_broker: BrokerDTO;
  created_at: string;
  updated_at: string;
}

const authApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserCompany: build.query<CompanyDTO, string>({
      query: (companyId) => ({
        url: `/companies/${companyId}`,
        method: "GET",
        providesTags: ["Company"],
      }),
    }),
  }),
});

export const { useGetUserCompanyQuery } = authApiSlice;
