import { baseApi } from "@/config/BaseQuery";

export interface ParentOrderQuantities {
  working: number;
  matched: number;
  tradable: number;
}

export enum ParentOrderState {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Archived = "ARCHIVED",
  Cancelled = "CANCELLED", // created and then cancelled, without any order
  Filled = "FILLED",
}

export type ParentOrderDTO = {
  parent_order_id: string;
  quantity: number;
  instrument_id: string;
  direction: Direction;
  created_by: string;
  quantities: ParentOrderQuantities;
  state: ParentOrderState;
  created_at: string;
  updated_at: string;
};

export interface PostParentOrderPayload {
  direction: Direction;
  instrument_id: string;
  quantity: number;
}

export enum Direction {
  BUY = "BUY",
  SELL = "SELL",
}

const parentApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getParentOrders: build.query<ParentOrderDTO[], void>({
      query: () => `/parent-orders?state=${ParentOrderState.Active},${ParentOrderState.Filled}`,
      providesTags: [{ type: "ParentOrders" }],
    }),
    getParentOrder: build.query<ParentOrderDTO, string>({
      query: (id: string) => `/parent-orders/${id}`,
      providesTags: [{ type: "SelectedParentOrder" }],
    }),
    createParentOrder: build.mutation<ParentOrderDTO, PostParentOrderPayload>({
      query: (body) => ({
        url: "/parent-orders",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ParentOrders", "SelectedParentOrder"],
    }),
    deleteParentOrder: build.mutation<void, string>({
      query: (id) => ({
        url: `/parent-orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ParentOrders", "SelectedParentOrder"],
    }),
  }),
});

export { parentApiSlice };

export const {
  useGetParentOrdersQuery,
  useGetParentOrderQuery,
  useCreateParentOrderMutation,
  useDeleteParentOrderMutation,
} = parentApiSlice;
