import { baseApi } from "@/config/BaseQuery";

export interface InstrumentDTO {
  instrument_id: string;
  name: string;
  tick_size: number;
  min_quantity: number;
  description: string;
  exchange: string;
  country: string;
  term: string;
  created_at: string;
  updated_at: string;
}

const instrumentsApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInstruments: build.query<InstrumentDTO[], void>({
      query: () => "/instruments",
      keepUnusedDataFor: 900, // 15 mins.
      providesTags: [{ type: "Instruments", id: "LIST" }],
    }),
  }),
});

export const { useGetInstrumentsQuery } = instrumentsApiSlice;
