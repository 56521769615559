import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import { metricFormatter } from "@/utils/String";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InstrumentDTO } from "../instruments/instrumentsApiSlice";
import { MatchRequestDTO } from "../orders/match/matchApiSlice";
import { Direction } from "../orders/parent/parentApiSlice";
import { RfqDTO } from "./rfqApiSlice";

export type Notification = MatchRequestDTO | RfqDTO | null;

export interface NotificationSliceState {
  /**
   * The notification currently being viewed in detail.
   * Set when selecting the "view" button in NotificationsListItem.tsx.
   */
  notification: Notification;
  /**
   * The current RFQ being responded to.
   * Set when selecting the "accept" button in NotificationDetails.tsx.
   */
  activeRfq: RfqDTO | null;
}

const initialState: NotificationSliceState = {
  notification: null,
  activeRfq: null,
};

export const notificationSlice = createAppSlice({
  name: "notification",
  initialState,
  reducers: (create) => ({
    setActiveNotification: create.reducer((state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    }),
    setActiveRfq: create.reducer((state, action: PayloadAction<RfqDTO | null>) => {
      state.activeRfq = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectActiveNotification: (state) => state.notification,
    selectActiveRfq: (state) => state.activeRfq,
  },
});

export const { setActiveNotification, setActiveRfq } = notificationSlice.actions;

export const { selectActiveNotification, selectActiveRfq } = notificationSlice.selectors;

export const isMatchRequest = (n: Notification) => {
  return n !== null && "order_match_request_id" in n;
};

export const isRfq = (n: Notification) => {
  return n !== null && "rfq_id" in n;
};

export const getNotificationTitle = (n: Notification, instrument: InstrumentDTO | undefined) => {
  if (isMatchRequest(n)) {
    return "Match for approval";
  }
  if (isRfq(n) && instrument) {
    return `RFQ: ${instrument.name}`;
  }
  return "Unknown notification type";
};

export const getNotificationSubtitle = (n: Notification, intrument: InstrumentDTO | undefined) => {
  if (isMatchRequest(n)) {
    return "Accept this match for your working order.";
  }
  if (isRfq(n) && intrument) {
    return `This request is for ${intrument.term} for the ${intrument.name}.`;
  }
  return "Please contact a member of the InTick team.";
};

export const getNotificationBody = (n: Notification, instrument: InstrumentDTO | undefined): string => {
  if (isMatchRequest(n)) {
    const notification = n as MatchRequestDTO;
    const oppositeDirection = notification.direction === Direction.BUY ? "Sell" : "Buy";
    return `${oppositeDirection} ${metricFormatter(n.quantity, 1)} @ ${notification.limit_price}`;
  }
  if (isRfq(n) && instrument) {
    return getNotificationSubtitle(n, instrument);
  }
  return "Please contact a member of the InTick team.";
};

export const getNotificationCaption = (n: Notification): string => {
  if (isRfq(n)) return getNotificationSender(n);
  else return "";
};

export const getNotificationSender = (n: Notification): string => {
  if (isMatchRequest(n)) {
    return ``;
  }
  if (isRfq(n)) {
    return `Client: ${n.created_by_company_name}`;
  }
  return "Client: -";
};

/**
 * Reverses the direction sent by the API in order to reflect the direction
 * of the parent order that created the RFQ request.
 */
export const getRfqDirection = (rfq: Notification): string => {
  if (!isRfq(rfq) || !rfq || !rfq.show_direction) return "-";
  return rfq.direction;
};
