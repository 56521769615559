import { selectInstruments, setInstrument } from "@/features/instruments/instrumentSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { PropsWithChildren, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { ParentOrderDTO } from "../parentApiSlice";
import { selectParent, setParentOrder } from "../parentSlice";
import ParentOrderCard from "./ParentOrderCard";
import { setPrice } from "@/features/orderbook/pricesSlice";

export interface ParentOrderCarouselProps extends PropsWithChildren {
  orders: ParentOrderDTO[];
}

const ORDERS_PER_PAGE = 3;
const getPagesCount = (orders: ParentOrderDTO[]) => Math.ceil(orders.length / ORDERS_PER_PAGE);
const selectFirstVisibleOrdersIndices = (orders: ParentOrderDTO[]) => {
  const indices = Object.keys(orders).map((index) => parseInt(index));
  const firstThree = indices.slice(0, 3);
  return firstThree;
};

const selectVisibleOrdersIndices = (orders: ParentOrderDTO[]) => {
  const indices = Object.keys(orders).map((index) => parseInt(index));
  const lastThree = indices.slice(Math.max(indices.length - 3, 0));
  return lastThree;
};
const selectVisibleOrders = (orders: ParentOrderDTO[], indices: number[]): ParentOrderDTO[] => {
  if (indices.length === 0) return [];
  return indices.map((index) => orders[index] as ParentOrderDTO);
};
const goBack = (indices: number[]) => {
  const previousIndices = indices.reduce((acc, cur) => {
    const newIndex = Math.max(0, cur - ORDERS_PER_PAGE);
    if (acc.includes(newIndex)) {
      acc.push(acc.length);
    } else {
      acc.push(newIndex);
    }
    return acc;
  }, [] as number[]);
  return previousIndices;
};
const goForward = (currentIndices: number[], orders: ParentOrderDTO[]) => {
  const orderIndices = Object.keys(orders).map((index) => parseInt(index));
  const newIndex = (currentIndices[2] as number) + 3;
  let newList: number[] = [];
  if (orderIndices.includes(newIndex)) {
    const start = currentIndices[0] as number;
    newList = [start + 3, start + 4, start + 5];
  } else {
    const last = orderIndices.length;
    newList = [last - 3, last - 2, last - 1];
  }
  return newList;
};
export default function ParentOrderCarousel({ orders, children }: ParentOrderCarouselProps) {
  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);
  const instruments = useAppSelector(selectInstruments);

  const [visibleOrders, setVisibleOrders] = useState<ParentOrderDTO[]>([]);
  // const [lastVisibleIndex, setLastVisibleIndex] = useState(totalOrders - 1);

  const [localOrders, setLocalOrders] = useState<ParentOrderDTO[]>([]);
  const [pagesCount, setPagesCount] = useState(getPagesCount(orders));
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleOrdersIndices, setVisibleOrdersIndices] = useState<number[]>([]);

  const isClickUpPageDisabled = () => {
    if (!orders.length || !visibleOrders.length) {
      return true;
    }
    return orders[0]?.parent_order_id === visibleOrders[0]?.parent_order_id;
  };

  const isClickDownPageDisabled = () => {
    if (!orders.length || !visibleOrders.length) {
      return true;
    }
    return orders[orders.length - 1]?.parent_order_id === visibleOrders[2]?.parent_order_id;
  };

  useEffect(() => {
    if (orders.length === 1 && orders[0] && !selectedParent) {
      const instrument = instruments.find((x) => x.instrument_id === orders[0]?.instrument_id);
      if (instrument) {
        dispatch(setPrice(null));
        dispatch(setParentOrder(orders[0]));
        dispatch(setInstrument(instrument));
      }
    }
  }, [orders, instruments]);

  useEffect(() => {
    const pagesCount = getPagesCount(orders);
    setPagesCount(pagesCount);
    // Order added
    if (localOrders.length < orders.length) {
      setVisibleOrdersIndices(selectFirstVisibleOrdersIndices(orders));
      setCurrentPage(1);
    }
    // Order removed
    if (localOrders.length > orders.length) {
      setCurrentPage(pagesCount);
      setVisibleOrdersIndices(selectFirstVisibleOrdersIndices(orders));
    }
    setLocalOrders(orders);
  }, [orders.length]);

  useEffect(() => {
    const visible = selectVisibleOrders(orders, visibleOrdersIndices);
    setVisibleOrders(visible);
  }, [visibleOrdersIndices]);

  const handleCarousel = (action: "scroll-up" | "scroll-down" | "scroll-to-bottom") => {
    // If adding a new parent order, always show it
    if (action === "scroll-to-bottom") {
      setVisibleOrdersIndices(selectVisibleOrdersIndices(orders));
      return;
    }

    // If there are less than 3 parent orders, then there's no scrolling required.
    if (visibleOrders.length !== ORDERS_PER_PAGE || orders.length < ORDERS_PER_PAGE) return;

    if (action === "scroll-up") {
      setVisibleOrdersIndices(goBack(visibleOrdersIndices));
      setCurrentPage((prev) => prev - 1);
    } else {
      setVisibleOrdersIndices(goForward(visibleOrdersIndices, orders));
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <Stack>
      <TransitionGroup>
        {visibleOrders.map((order) => (
          <Collapse timeout={400} key={order.parent_order_id}>
            <ParentOrderCard order={order} />
          </Collapse>
        ))}
      </TransitionGroup>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {children}

        {orders.length > ORDERS_PER_PAGE && (
          <Stack direction="row" alignItems="center">
            <Tooltip title="Scroll up">
              {/* Span required because the button can be disabled */}
              <span>
                <IconButton size="small" onClick={() => handleCarousel("scroll-up")} disabled={isClickUpPageDisabled()}>
                  <ExpandCircleDownOutlinedIcon sx={{ transform: "rotate(180deg)", height: 34, width: 34 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Scroll down">
              {/* Span required because the button can be disabled */}
              <span>
                <IconButton
                  size="small"
                  onClick={() => handleCarousel("scroll-down")}
                  disabled={isClickDownPageDisabled()}
                >
                  <ExpandCircleDownOutlinedIcon sx={{ height: 34, width: 34 }} />
                </IconButton>
              </span>
            </Tooltip>
            {/* <Typography variant="caption" mx={1}>{`${lastVisibleIndex - 1} - ${
              lastVisibleIndex + 1
            } / ${totalOrders}`}</Typography> */}
            <Typography variant="caption" mx={1}>
              {`${currentPage} / ${pagesCount}`}
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
